.MainContent {
  display: grid;
  grid-template-rows: 64px auto;
  grid-template-columns: 235px auto;
  height: 100vh;
  transition: 300ms;
}

.MainContent-hidden {
  display: grid;
  grid-template-rows: 64px auto;
  grid-template-columns: 0px auto;
  height: 100vh;
  transition: 300ms;
}

.MainContent__content {
  background-color: #f7f9fc;
  grid-row: 2;
  grid-column: 2;
  padding: 20px;
  overflow-y: auto;
  z-index: 1;
  min-width: 420px;
}

.Header {
  grid-row: 1;
  grid-column: 1/3;
  background-color: #f7f9fc;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;
}

.Nav {
  grid-row: 2;
  grid-column: 1;
  background: #1A242F;
  min-height: calc(100vh - 64px);
  overflow: auto;
}

@media (max-width: 500px) {
  .MainContent {
    grid-template-columns: auto;
  }
  .Nav {
    grid-column: 1/2;
  }

  .Nav {
    position: relative;
    left: 0;
  }
}