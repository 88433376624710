.WordSearchRow {
  cursor: default;

  .word {
    display: inline-block;
    padding-right: 5px;
    width: calc(100% - 200px);
    vertical-align: middle;
    white-space: break-spaces;

    .text {
      color: #2582e7;
      font-size: 14px;
      cursor: pointer;
    }

    .translation {
      font-size: 12px;
      overflow: hidden;
    }
  }

  .word-pic {
    display: inline-block;
    width: 35px;
    height: 35px;
    object-fit: cover;
    vertical-align: middle;
    cursor: pointer;
  }
}
