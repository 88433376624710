.PicChanger {
  display: flex;
  flex-direction: column;
  align-items: center;

  #contained-button-file {
    display: none;
  }
  &__pic {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
  }
}
