.PlanBlock {
  display: block;
  padding-left: 10px;
  width: 250px;

  &__title {
    display: block;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.33;
    text-decoration: underline;
  }

  &__item {
    display: block;
    padding-bottom: 2px;
  }

  &__notes {
    margin-top: 10px;
    display: block;
    unicode-bidi: embed;
    font-family: monospace;
    white-space: pre;
  }
}
