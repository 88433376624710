.StatisticBlock {
  display: block;
  width: 280px;
  padding: 10px;
  border: 1px solid #eeeeee;
  margin-bottom: 20px;

  &__group-data {
    display: block;
  }

  &__notes {
    margin-top: 10px;
    display: block;
    unicode-bidi: embed;
    font-family: monospace;
    white-space: pre;
  }
}

.StatisticBlock.done-block {
  border: 1px solid green;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
}
