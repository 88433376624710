:root {
  --shadow-color: 228deg 7% 43%;
  --shadow-elevation-low: -0.2px 0.4px 0.5px hsl(var(--shadow-color) / 0.34),
  -0.3px 0.7px 0.9px -1.2px hsl(var(--shadow-color) / 0.34),
  -0.8px 1.8px 2.2px -2.5px hsl(var(--shadow-color) / 0.34);
  --shadow-elevation-medium: -0.2px 0.4px 0.5px hsl(var(--shadow-color) / 0.36),
  -0.6px 1.5px 1.8px -0.8px hsl(var(--shadow-color) / 0.36),
  -1.6px 3.7px 4.5px -1.7px hsl(var(--shadow-color) / 0.36),
  -3.8px 9px 11px -2.5px hsl(var(--shadow-color) / 0.36);
  --shadow-elevation-high: -0.2px 0.4px 0.5px hsl(var(--shadow-color) / 0.34),
  -1.1px 2.6px 3.2px -0.4px hsl(var(--shadow-color) / 0.34),
  -2.1px 4.9px 6px -0.7px hsl(var(--shadow-color) / 0.34),
  -3.4px 8px 9.8px -1.1px hsl(var(--shadow-color) / 0.34),
  -5.4px 12.8px 15.6px -1.4px hsl(var(--shadow-color) / 0.34),
  -8.4px 20px 24.4px -1.8px hsl(var(--shadow-color) / 0.34),
  -12.8px 30.4px 37.1px -2.1px hsl(var(--shadow-color) / 0.34),
  -18.9px 44.8px 54.7px -2.5px hsl(var(--shadow-color) / 0.34);
}

$scrollShadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.App {
  font-family: 'Nunito', sans-serif;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.text-center {
  text-align: center;
}

.custom-scroll {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: $scrollShadow;
    border-radius: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: $scrollShadow;
    background-color: #8a8989;
  }
}

.App-page {
  width: 500px;
  margin: auto;
  margin-top: 5%;

  &__wrapper {
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  &__title {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.33;
    margin-bottom: 20px;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed rgba(0, 0, 0, 0.25);
  }

  &__flex-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.popup-content {
  min-width: 500px;
  display: flex;
  flex-direction: column;
}

.flex-content {
  display: flex;
}

.close-popup-icon {
  float: right;
}

.word-translation {
  font-size: 28px;
  color: #c18686;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}