.SearchWord {
  margin-left: 20px;
  position: relative;
  width: 400px;

  &__hints {
    position: absolute;
    top: 47px;
    width: 400px;
    padding: 8px 0;
    overflow: auto;
    max-height: 40vh;
    min-height: 40px;
    background: white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.6);

    .add-word-block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .word {
        width: 80%;
        text-align: left;
        padding-left: 10px;
      }
    }
  }
}
