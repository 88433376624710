.Header {
  position: relative;
  z-index: 10;
  box-shadow: rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;

  &__user {
    width: 225px;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 10px;

    .name {
      color: #8a8989;
      padding-left: 10px;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 225px);
  }
}
